<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="flex flex-col items-center">
    <div class="max-w-[650px] lg:max-w-full">
      <div class="md:flex md:flex-col md:items-center md:text-center">
        <SubTitle v-if="data?.subtitle" class="text-blue">
          {{ data?.subtitle }}
        </SubTitle>
        <Title
          v-if="data?.title"
          class="mb-5 leading-none md:w-[650px] lg:mb-6 lg:leading-none"
        >
          {{ data?.title }}
        </Title>
        <div
          v-if="data?.copy"
          class="font-base prose lg:prose-lg prose-p:text-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:prose-p:text-xl font-sans leading-[21px] md:w-[650px] lg:leading-[26px]"
          v-html="data?.copy"
        ></div>
        <Link
          v-if="data?.link"
          :title="data.link.title"
          :type="'outline'"
          :link="data.link.url"
          :class="['my-6 w-[300px] md:w-[197px]']"
        />
      </div>
      <div class="xmd:pt-8 w-full">
        <CollapseImageGrid
          v-for="(item, index) in data?.cards"
          :key="item?.title || index"
          :open="isOpen"
          @click="toggleOpen"
        >
          <template #header> {{ item.title }}</template>
          <template #content>
            <div
              :class="[
                width < 365
                  ? 'my-5 -ml-1 grid grid-cols-2 gap-3'
                  : 'my-5 grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-12',
              ]"
            >
              <div
                v-for="i in sortedArray(item?.sub)"
                :class="[
                  i.links
                    ? 'relative w-[165px] overflow-hidden rounded-xl border border-blue-lighter shadow-lg md:h-[374px] md:w-[230px]'
                    : 'relative w-[165px] overflow-hidden rounded-xl md:h-[374px] md:w-[230px]',
                ]"
              >
                <div
                  :key="i.cardTitle"
                  class="flex h-[307px] w-[165px] flex-col justify-items-center"
                >
                  <div
                    :class="[
                      i.links
                        ? 'flex h-[179px] w-full flex-col justify-center gap-1 text-center md:h-[220px] md:w-[230px]'
                        : 'flex h-[179px] w-full flex-col justify-center gap-1 rounded-xl border border-blue-lighter text-center shadow-md md:h-[220px] md:w-[230px]',
                    ]"
                  >
                    <GridImage
                      v-if="i?.image"
                      :url="i.image.url"
                      :class="'self-center'"
                    />
                    <h3>{{ i?.cardTitle }}</h3>
                  </div>
                  <div
                    v-if="i?.links"
                    class="absolute bottom-0 flex h-32 w-full flex-col justify-center gap-y-3 p-2 text-center md:h-[153px] md:w-[228px] md:gap-y-4"
                  >
                    <LinkSecondary
                      :title="i?.links[0]?.title"
                      :link="i?.links[0]?.url"
                      :class="[
                        'self-center rounded-full bg-blue px-4 py-1.5 font-serif text-lg font-normal hover:bg-blue-dark lg:w-[130px] lg:!text-lg',
                      ]"
                      :arrow-class="'ml-2.5'"
                    />
                    <Link
                      v-if="i?.links?.length > 1"
                      :title="i?.links[1]?.title"
                      :link="i?.links[1]?.url"
                      :class="[
                        'w-[138px] self-center whitespace-nowrap !rounded-full border-none bg-green !px-3.5 !py-1.5 font-serif text-lg font-normal hover:bg-green-dark lg:w-[140px] lg:!text-lg',
                      ]"
                    />
                    <p
                      v-if="i?.links?.length === 1"
                      class="self-center font-serif text-lg uppercase text-[#D95151]"
                    >
                      Ingen rapport
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </CollapseImageGrid>
      </div>
    </div>
  </Container>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useWindowSize } from '@vueuse/core'

import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Link from '../atoms/Link.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import Container from '../atoms/Container.vue'
import GridImage from '../atoms/GridImage.vue'
import CollapseImageGrid from '../atoms/CollapseImageGrid.vue'

import { ICardData, ILinkData, IImageData } from '../../types'

interface ISub {
  cardTitle?: string
  links?: ILinkData[]
  image?: IImageData
}

interface ICardSmallImageGridBlock extends ICardData {
  sub?: ISub[]
}

export interface ISmallImageGridBlock {
  id?: string
  title?: string
  subtitle?: string
  cards?: ICardSmallImageGridBlock[]
  link?: ILinkData
  copy?: string
}

interface Props {
  data: ISmallImageGridBlock
}

defineProps<Props>()

const isOpen = ref(false)
const toggleOpen = () => {
  isOpen.value = !isOpen.value
}

const { width } = useWindowSize()

const sortedArray = (array?: ISub[]) => {
  if (array === undefined) return []

  return array.sort((a, b) => {
    if (a.cardTitle && b.cardTitle) {
      return a.cardTitle > b.cardTitle ? 1 : -1
    } else {
      // handle cases where either cardTitle is undefined
      return 0
    }
  })
}
</script>
