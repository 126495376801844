<template>
  <form>
    <div class="rounded-xl bg-blue-lighter px-6 py-4 lg:rounded-2xl lg:p-9">
      <h4 class="mb-10 font-serif text-xl text-black lg:text-2xl">
        {{
          isProtest ? $t('petitionForm.protestTitle') : $t('petitionForm.petitionTitle')
        }}
      </h4>
      <div class="flex flex-col lg:flex-row lg:space-x-4">
        <Input
          v-model="data.fname"
          :label="$t('petitionForm.firstName')"
          style-input="border !border-blue-dark focus:border-blue-dark mb-4 shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full lg:mb-7"
          required
        />
        <Input
          v-model="data.lname"
          :label="$t('petitionForm.lastName')"
          style-input="border !border-blue-dark focus:border-blue-dark mb-4 shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full lg:mb-7"
          required
        />
      </div>
      <div>
        <Input
          v-model="data.email"
          label="E-mail"
          type="email"
          style-input="border !border-blue-dark focus:border-blue-dark mb-4 shadow-[0_12px_33px_0_rgba(5, 58, 65, 0.04)] w-full"
          required
        />

        <Button
          :title="$t('petitionForm.buttonLabel')"
          class="mb-4 mt-6 w-full px-6 lg:mb-6 lg:mt-10 lg:w-auto lg:px-12"
          color="pink"
          @click.prevent="submitPetition"
        />
        <div
          class="font-sans text-xs leading-normal text-black lg:text-sm lg:leading-relaxed [&_p]:text-xs lg:[&_p]:text-sm"
        >
          {{ $t('petitionForm.info') }}
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { reactive } from 'vue';
import { navigateTo } from '#app';
import { Input, Button } from 'refresh-ui';

const data = reactive({
  fname: '',
  lname: '',
  email: '',
});

const props = defineProps({
  petitionId: String,
  isProtest: Boolean,
});

const submitPetition = async () => {
  if (!props.petitionId) {
    return;
  }

  const url = new URL('https://interact.djurensratt.se/agera/' + props.petitionId);

  for (let [name, value] of Object.entries(data)) {
    url.searchParams.set(name, value);
  }

  await navigateTo(url.href, { external: true });
};
</script>
