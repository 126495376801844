<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="relative">
    <Container class="mb-11 text-center lg:mb-9">
      <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
      <Title>{{ data?.title }}</Title>
    </Container>
    <ClientOnly>
      <Swiper
        v-if="data.cards && data.cards?.length"
        :modules="[Controller, Navigation, Pagination]"
        :controller="{ control: controlledSwiper }"
        :slides-per-view="4"
        :space-between="30"
        :centered-slides="true"
        :loop="loop"
        :pagination="pagination"
        :breakpoints="breakpoints"
        :initial-slide="
          data.cards.length === 3 ? 1 : data?.mode === 'right' ? data.cards.length - 1 : 0
        "
        @swiper="setSwiper"
        @slide-change="onSlideChange"
        @slider-move="startMove"
        @transition-start="startMove"
        @transition-end="stopMove"
        @slide-change-transition-end="stopMove"
      >
        <div
          class="group-[.is-blue]:from-blue-lighter absolute bottom-0 left-0 top-0 z-30 w-[10%] bg-gradient-to-r transition-all duration-300 group-[.is-white]:from-white"
        ></div>
        <div
          class="group-[.is-blue]:to-blue-lighter absolute bottom-0 right-0 top-0 z-30 w-[10%] bg-gradient-to-r from-transparent transition-all duration-300 group-[.is-white]:to-white"
        ></div>

        <SwiperSlide
          v-for="(item, index) in data?.cards"
          v-slot="{ isActive, isPrev, isNext }"
          :key="index"
        >
          <div
            class="relative flex aspect-[0.68] flex-col items-center justify-center overflow-hidden rounded-2xl"
          >
            <div
              :class="[
                !isActive && 'scale-[0.85]',
                prevPrev === index && 'lg:ml-[15%]',
                nextNext === index && 'lg:mr-[15%]',
                isPrev && 'ml-[10%] xl:ml-0',
                isNext && 'mr-[10%] xl:mr-0',
              ]"
              class="h-full w-full overflow-hidden rounded-2xl transition-all duration-300"
            >
              <NuxtImg
                v-if="item?.image?.url"
                :src="item.image.url"
                :alt="item.image.alt || item.title"
                class="absolute h-full w-full object-cover"
                loading="lazy"
              />
              <div v-if="item?.category" class="relative z-50 px-4 py-5">
                <BadgeCategory :pink="true">{{ item?.category }}</BadgeCategory>
              </div>
              <div
                class="from-blue-darker via-blue-dark absolute bottom-0 h-2/4 w-full rounded-2xl bg-gradient-to-t"
              ></div>
            </div>
            <div
              class="absolute bottom-0 w-full p-7 text-white transition-all"
              :class="[
                !isActive && 'p-14 lg:pb-16 2xl:px-16 2xl:pb-20',
                prevPrev === index && 'lg:ml-[15%]',
                nextNext === index && 'lg:mr-[15%]',
                isPrev && 'ml-[10%] xl:ml-0',
                isNext && 'mr-[10%] xl:mr-0',
              ]"
            >
              <h3 class="mb-2 font-serif text-2xl leading-none xl:text-3xl">
                {{ item.title }}
              </h3>
              <div class="mb-4 font-sans text-base leading-snug xl:text-lg">
                <span
                  :class="[isActive ? 'line-clamp-4' : 'line-clamp-3']"
                  v-html="item?.copy"
                ></span>
              </div>
              <LinkSecondary
                v-if="item?.link"
                :title="item.link.title"
                :link="item.link.url"
                :external="item.link?.type === 'ext'"
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
    <SliderPagination
      v-if="loop && data.cards && data.cards.length"
      :slider="controlledSwiper"
      :slides="data?.cards.length"
      :class="[data?.cards?.length <= 3 && 'lg:hidden']"
      :active-index="realIndex"
    />
    <SliderNavigation
      v-if="data.cards && data.cards.length"
      :slider="controlledSwiper"
      :class="[data?.cards?.length <= 3 && 'lg:hidden']"
    />
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import type { Ref } from 'vue'
import { Controller, Navigation, Pagination, Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useWindowSize } from '@vueuse/core'

import Container from '../atoms/Container.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import BadgeCategory from '../atoms/BadgeCategory.vue'
import SliderPagination from '../atoms/SliderPagination.vue'
import NuxtImg from '../atoms/NuxtImg.vue'
import { ICardData } from '../../types'

interface ITallCardSliderCard extends ICardData {
  category?: string
}

export interface ITallCardSliderBlock {
  id: string
  title?: string
  subtitle?: string
  mode?: 'infinite' | 'right'
  cards?: ITallCardSliderCard[]
}

interface Props {
  data: ITallCardSliderBlock
}

const props = defineProps<Props>()

const { width } = useWindowSize()

const controlledSwiper: Ref<SwiperType | undefined> = ref()
const realIndex = ref(0)
const showShadow = ref(true)

const loop = computed(() => {
  const length = props.data?.cards ? props.data?.cards.length : 0
  return length > 3 && props.data?.mode === 'infinite'
})

const length = computed(() => (props.data?.cards ? props.data?.cards.length : 0))

const pagination = computed(() => {
  return {
    clickable: true,
    dynamicBullets: true,
    horizontalClass: loop.value
      ? 'swiper-pagination-horizontal hidden z-10'
      : length.value <= 3
      ? 'swiper-pagination-horizontal lg:hidden z-10'
      : 'swiper-pagination-horizontal z-10',
  }
})

const breakpoints = {
  320: {
    slidesPerView: 1.3,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.5,
    spaceBetween: 50,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 30,
  },
}

const widthScreen = computed(() => width.value)

watch(widthScreen, () => {
  controlledSwiper.value?.update()
})

const startMove = () => {
  showShadow.value = false
}
const stopMove = () => {
  showShadow.value = true
}

const prevPrev = computed(() => {
  const i = realIndex.value - 2
  return i >= 0 ? i : length.value + i
})
const nextNext = computed(() => {
  const i = realIndex.value + 2
  return i >= length.value ? i - length.value : i
})

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
  realIndex.value = controlledSwiper.value?.realIndex
}
const onSlideChange = () => {
  realIndex.value = controlledSwiper.value?.realIndex || 0
}
</script>

<style>
.swiper {
  padding-bottom: 70px;
}
.swiper-pagination {
  margin-top: 70px;
}
</style>
