<template>
  <div>
    <div
      ref="blockRef"
      class="bg-blue-lighter relative pb-8 lg:min-h-[722px] lg:pb-16 lg:pt-12"
    >
      <Image
        v-if="data?.image?.url"
        :url="data?.image?.url"
        :alt="data.image.alt"
        class="right-0 aspect-[378/310] w-full lg:!absolute lg:bottom-5 lg:top-5 lg:aspect-auto lg:rounded-bl-2xl lg:rounded-tl-2xl xl:aspect-[832/684] xl:h-[684px] xl:max-w-[832px]"
        :style="imageStyle"
      />
      <Container class="mt-10 flex lg:mt-0">
        <div class="basis-full lg:basis-1/2">
          <SubTitle class="text-blue">{{ data?.subtitle }}</SubTitle>
          <Title>{{ data?.title }}</Title>

          <div
            class="lg:scrollbar-thin lg:scrollbar-track-white lg:scrollbar-thumb-blue lg:scrollbar-track-rounded-full lg:scrollbar-thumb-rounded-full -mb-4 -ml-4 mt-6 overflow-hidden pb-4 pl-4 pt-2 transition-all duration-700 ease-in-out lg:mt-14 lg:!max-h-[476px] lg:overflow-y-scroll"
            :style="heightBlock"
          >
            <ul class="space-y-2">
              <li v-for="(link, index) in data?.links" :key="index">
                <a
                  :href="`#${link.id}`"
                  class="text-blue hover:text-blue-dark cursor-pointer font-sans text-base font-bold uppercase drop-shadow-[0_1px_5px_rgba(0,155,181,0.4)] hover:drop-shadow-none lg:text-xl"
                >
                  {{ link.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div
        v-if="!isShow"
        class="from-blue-lighter via-blue-lighter absolute bottom-0 flex h-32 w-full items-end justify-center bg-gradient-to-t to-transparent pb-8 lg:hidden"
      >
        <button
          class="border-blue flex h-9 w-9 items-center justify-center rounded-lg border-[3px]"
          aria-label="open"
          @click="isShow = true"
        >
          <AtomsIconsPlus />
        </button>
      </div>
    </div>
    <ButtonUp :block-to="blockRef" />
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, computed, CSSProperties } from 'vue'
import { useWindowSize } from '@vueuse/core'
import type { Ref } from 'vue'

import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Container from '../atoms/Container.vue'
import Image from '../atoms/Image.vue'
import ButtonUp from '../atoms/ButtonUp.vue'
import { IImageData } from '../../types'

type HeightBlockStyle = CSSProperties & {
  maxHeight: string
}

export interface IOnThisPageBlock {
  id?: string
  image?: IImageData
  title?: string
  subtitle?: string
  links?: {
    id: string
    title: string
  }[]
}

interface Props {
  data: IOnThisPageBlock
}

defineProps<Props>()

const imageWidth = ref('100%')
const isShow = ref(false)

const blockRef: Ref<HTMLDivElement | undefined> = ref()

const { width: widthWindow } = useWindowSize()

const imageStyle = computed(() => {
  return widthWindow.value > 1024
    ? {
        width:
          typeof imageWidth.value === 'string'
            ? imageWidth.value
            : imageWidth.value + 'px',
      }
    : { width: '100%' }
})

const heightBlock = computed<HeightBlockStyle>(() => {
  return isShow.value ? { maxHeight: '5000px' } : { maxHeight: '476px' }
})

onMounted(() => {
  setTimeout(() => {
    imageWidth.value = widthWindow.value / 2 - 38 + 'px'
  }, 100)
})

watch(widthWindow, () => {
  imageWidth.value = '0'

  setTimeout(() => {
    imageWidth.value = widthWindow.value / 2 - 38 + 'px'
  }, 100)
})
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
