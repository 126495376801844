<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="!max-w-[1362px]">
    <Collapse
      v-for="(card, index) in data?.cards"
      :key="card.id"
      :open="data?.firstExpanded && index === 0"
    >
      <template #header>{{ card?.title }}</template>
      <template #content>
        <div
          class="flex flex-col"
          :class="[
            reverse(index)
              ? 'justify-between lg:flex-row-reverse'
              : 'justify-between lg:flex-row',
          ]"
        >
          <Image
            v-if="card?.cta?.image?.url"
            :url="card?.cta?.image?.url"
            :alt="card?.cta?.image?.alt"
            :class="[
              'aspect-[3/2] w-full shrink-0 overflow-hidden rounded-b-2xl lg:aspect-[3/2.5]  lg:basis-[45%] xl:basis-[38%]',
              reverse(index)
                ? ' lg:rounded-bl-2xl lg:rounded-br-none lg:rounded-tl-2xl'
                : ' lg:rounded-bl-none lg:rounded-br-2xl lg:rounded-tr-2xl',
            ]"
          />

          <div
            v-if="card?.cta?.subtitle && card?.cta?.title && card?.cta?.copy"
            class="shrink px-4 py-6 lg:px-28 lg:pt-10 xl:pt-24"
          >
            <div class="max-w-2xl">
              <SubTitle v-if="card?.cta?.subtitle" class="text-blue">
                {{ card?.cta?.subtitle }}
              </SubTitle>
              <Title v-if="card?.cta?.title">
                {{ card?.cta?.title }}
              </Title>
              <div
                v-if="card?.cta?.copy"
                class="mb-10 mt-3.5 font-sans text-base leading-snug text-black lg:text-lg lg:leading-normal"
                v-html="card?.cta?.copy"
              ></div>

              <LinkSecondary
                v-if="card?.cta?.link && !card?.copy"
                :title="card?.cta?.link?.title"
                :link="card?.cta?.link.url"
                :external="card?.cta?.link?.type === 'ext'"
                type="blue"
              />
            </div>
          </div>
        </div>
        <div class="max-w-4xl px-4 lg:pl-28">
          <article
            v-if="card?.copy"
            class="custom-list prose prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:prose-lg mb-16 mt-5 lg:mt-16"
            v-html="card?.copy"
          ></article>

          <LinkSecondary
            v-if="card?.cta?.link && card?.copy"
            :title="card?.cta?.link?.title"
            :link="card?.cta?.link.url"
            :external="card?.cta?.link?.type === 'ext'"
            type="blue"
            class="mb-8 lg:mb-16"
          />
        </div>
        <NuxtLink
          v-if="card.linkVisible"
          :to="card.link"
          class="text-blue hover:text-blue-darker m-4 inline-block cursor-pointer self-end lg:mb-6 lg:mr-8 lg:mt-6"
        >
          <PermaLink />
        </NuxtLink>
      </template>
    </Collapse>
  </Container>
</template>

<script setup lang="ts">
import Collapse from '../atoms/Collapse.vue'
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Image from '../atoms/Image.vue'
import Container from '../atoms/Container.vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import PermaLink from '../atoms/Icons/PermaLink.vue'
import { ICardData, ICtaData } from '../../types'

export interface ICardFaqData extends ICardData {
  linkVisible?: boolean
  cta?: ICtaData
}

export interface IFaqBlock {
  id: string
  title?: string
  subtitle?: string
  firstExpanded?: boolean
  cards?: ICardFaqData[]
}

interface Props {
  data: IFaqBlock
}

defineProps<Props>()

const reverse = (index: number) => index % 2 === 0
</script>
