<template>
  <div class="relative">
    <ClientOnly fallback-tag="span" fallback="Loading ...">
      <Swiper
        :modules="[Controller, Navigation, Pagination]"
        :controller="{ control: controlledSwiper }"
        :slides-per-view="2"
        :space-between="20"
        :centered-slides="false"
        :loop="false"
        :init="true"
        :pagination="pagination"
        :breakpoints="breakpoints"
        :observer="false"
        :class="[cards.length > 2 && '!pb-[70px]']"
        @swiper="setSwiper"
      >
        <div
          v-if="cards.length > 2"
          class="absolute bottom-0 right-0 top-0 z-30 w-[10%] bg-gradient-to-r from-transparent transition-all duration-300 group-[.is-blue]:to-blue-lighter group-[.is-white]:to-white lg:w-[20%]"
        ></div>
        <SwiperSlide v-for="(item, index) in cards" :key="index">
          <NuxtLink
            :to="item.link?.url"
            class="relative flex aspect-square cursor-pointer flex-col items-center justify-center overflow-hidden rounded-md transition-all duration-300 lg:rounded-xl"
            :target="item.link?.type === 'ext' ? '_blank' : '_self'"
          >
            <NuxtImg
              :src="item.image.url"
              :alt="item.image.alt || item.title"
              class="absolute h-full w-full object-cover"
              loading="lazy"
              provider="cloudinary"
              fit="fill"
              width="350"
              height="350"
              sizes="xs:160px sm:350px md:350px lg:350px xl:350px xxl:350px"
            />
            <div
              class="absolute bottom-0 h-2/4 w-full rounded-md bg-gradient-to-t from-blue-darker lg:rounded-xl"
            ></div>
            <div
              class="absolute bottom-0 w-full px-3 py-2 text-white transition-all lg:px-6"
            >
              <SubTitle class="truncate">{{ item.subtitle }}</SubTitle>
              <h3
                class="truncate font-sans text-base font-bold leading-snug lg:font-serif lg:text-28 lg:leading-snug"
              >
                {{ item.title }}
              </h3>
            </div>
          </NuxtLink>
        </SwiperSlide>
        <SwiperSlide class="hidden lg:block" />
      </Swiper>
      <SliderNavigation v-if="cards.length > 2" :slider="controlledSwiper" />
    </ClientOnly>
  </div>
</template>

<script setup>
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { ref, computed, watch } from 'vue';
import { Controller, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useWindowSize } from '@vueuse/core';

import SubTitle from '../atoms/SubTitle.vue';
import SliderNavigation from '../atoms/SliderNavigation.vue';
import NuxtImg from '../atoms/NuxtImg.vue';

const { width } = useWindowSize();

const controlledSwiper = ref(null);

defineProps({
  cards: Array,
});

const pagination = {
  clickable: true,
  dynamicBullets: true,
};

const breakpoints = {
  320: {
    slidesPerView: 2.1,
    spaceBetween: 8,
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 20,
  },
  1921: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
  2300: {
    slidesPerView: 3,
    spaceBetween: 30,
  },
};
const widthScreen = computed(() => width.value);
watch(widthScreen, () => {
  controlledSwiper.value.update();
});

const setSwiper = swiper => {
  controlledSwiper.value = swiper;
};
</script>

<style scoped>
.swiper {
  padding-bottom: 0;
}
</style>
