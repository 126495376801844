<template>
  <div class="flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>

    <label
      v-for="(item, index) in [...Object.entries(options), ['_other_', otherLabel]]"
      :key="index"
      class="flex cursor-pointer items-center text-blue"
    >
      <input
        type="radio"
        :name="id + '[radios]'"
        :value="item[0]"
        :required="required"
        class="flex cursor-pointer items-center justify-center ring-0 ring-transparent ring-offset-0 checked:shadow-none checked:ring-0 focus:ring-0"
        @input="inputValue[$event.target.name] = $event.target.value"
      />
      <span class="ml-4 text-base text-black lg:text-lg">
        {{ item[1] }}
      </span>
    </label>
    <input
      v-show="inputValue[id + '[radios]'] === '_other_'"
      v-model="inputValue[id + '[other]']"
      type="text"
      :name="id + '[other]'"
      :required="inputValue[id + '[radios]'] === '_other_' && required"
      :placeholder="otherPlaceholder"
      class="max-h-[60px] w-full flex-1 rounded-md border border-blue-dark border-transparent p-3 leading-none outline-0 focus:border-blue-dark focus:border-transparent focus:ring-0 focus-visible:border-blue-dark lg:p-4 lg:text-xl lg:leading-none"
      @input="inputValue[$event.target.name] = $event.target.value"
    />
  </div>
</template>

<script setup>
import { reactive, watch } from 'vue';

const props = defineProps({
  id: String,
  title: String,
  required: Boolean,
  name: String,
  value: String,
  modelValue: Object,
  options: Object,
  otherLabel: String,
  otherPlaceholder: String,
});

const emit = defineEmits(['update:modelValue', 'setData']);

const inputValue = reactive({});

watch(inputValue, () => {
  if (inputValue[props.id + '[radios]'] !== '_other_') {
    inputValue[props.id + '[other]'] = '';
  }
  emit('setData', {
    key: props.name,
    value:
      inputValue[props.id + '[radios]'] !== '_other_'
        ? inputValue[props.id + '[radios]']
        : inputValue[props.id + '[other]'],
  });
  emit(
    'update:modelValue',
    inputValue[props.id + '[radios]'] !== '_other_'
      ? { [props.id]: inputValue[props.id + '[radios]'] }
      : { [props.id]: inputValue[props.id + '[other]'] },
  );
});
</script>

<style>
input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
  position: relative;
}

input[type='radio']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: none;
  background-color: currentColor;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

input[type='radio']:checked {
  background-color: transparent;
  border: 2px solid currentColor;
}

input[type='radio']:focus {
  outline: 0;
}
input[type='radio']:checked:hover {
  outline: 0;
  background-color: transparent;
  border: 2px solid currentColor;
}

[type='radio']:checked:focus {
  outline: 0;
  background-color: transparent;
  border: 2px solid currentColor;
}
</style>
