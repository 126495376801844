<template>
  <img v-if="storybook" :src="src" class="object-cover" :width="width" :height="height" />
  <nuxt-img
    v-else
    :src="src"
    :alt="alt"
    :provider="provider"
    :loading="loading"
    :sizes="sizes"
    :fit="fit"
    :width="width"
    :height="height"
  />
</template>

<script setup>
defineProps({
  src: String,
  alt: String,
  provider: String,
  loading: String,
  sizes: String,
  fit: String,
  width: [String, Number],
  height: [String, Number],
});
const storybook = import.meta.env.STORYBOOK === 'true';
</script>
