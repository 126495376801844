<template>
  <InfoFormBlock :data="componentData">
    <PetitionCounter
      :form-id="document?.fields?.nodeID || data.formId"
      class="mb-4 lg:mb-10"
    />
    <FormPetition :petition-id="data.formId" :is-protest="isProtest" />
  </InfoFormBlock>
</template>

<script setup>
import FormPetition from '@/components/molecules/FormPetition.vue';
import InfoFormBlock from './InfoFormBlock.vue';
import PetitionCounter from '@/components/molecules/PetitionCounter.vue';
import { useAsyncData, useNuxtApp } from '#app';
import { computed } from 'vue';
import { buildUrl } from 'cloudinary-build-url';
import { STORAGE_TYPES } from '@cld-apis/utils';
import { useI18n } from '#imports';
import { useRuntimeConfig } from '#app';
const { $client } = useNuxtApp();
const { t } = useI18n();
const config = useRuntimeConfig();

const props = defineProps({
  data: Object,
});

const { data: dataContentful } = await useAsyncData(
  `petition--${props.data.formId}`,
  () =>
    $client.getEntries({
      content_type: 'action',
      'fields.slug': props.data.formId,
      limit: 1,
    }),
);

const document = dataContentful?.value?.items[0];

const isProtest = document?.fields?.mailTemplate ? true : false;

const cloudinaryImg = buildUrl('https:' + document?.fields?.image?.fields?.file?.url, {
  cloud: {
    cloudName: config.public.cloudname,
    storageType: STORAGE_TYPES.FETCH,
  },
  transformations: {
    format: 'webp',
    resize: {
      type: 'fill',
      width: 1200,
      height: 600,
    },
  },
});

const componentData = computed(() => ({
  title: document?.fields?.name,
  subtitle: isProtest ? t('petitionForm.protestLabel') : t('petitionForm.petitionLabel'),
  copy: document?.fields?.cardTeaser || document?.fields?.excerpt,
  image: {
    url: cloudinaryImg,
    type: 'url',
    alt: document?.fields?.name,
  },
}));
</script>
