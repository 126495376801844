<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="data?.cards?.length > 1" class="relative">
    <ClientOnly>
      <Swiper
        :modules="[Controller, Navigation]"
        :controller="{ control: controlledSwiper }"
        :slides-per-view="1"
        :space-between="0"
        :loop="true"
        :allow-touch-move="false"
        @swiper="setSwiper"
      >
        <SwiperSlide
          v-for="(card, index) in [...data?.cards, ...data?.cards]"
          :key="index"
        >
          <div
            class="relative flex aspect-[3/2] w-full items-center lg:aspect-auto lg:h-[700px] lg:px-14"
          >
            <NuxtImg
              v-if="card?.image?.url"
              provider="cloudinary"
              :src="card?.image?.url"
              :alt="card?.image?.alt"
              class="absolute top-0 aspect-[375/230] w-full object-cover object-center lg:inset-0 lg:aspect-auto lg:h-full"
              loading="lazy"
              sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
              fit="fill"
              width="1920"
              height="700"
            />
            <Cta
              :sub-title="card?.subtitle"
              :title="card?.title"
              :content="card?.copyDesktop"
              :content-secondary="card?.copyMobile"
              :link="card?.link"
              :has-form-component="card.hasFormComponent"
              :form-component-id="card.formComponentId"
              class="mt-[45%] lg:mt-0"
            >
              <template #default="{ formComponentId }">
                <slot name="default" :form-component-id="formComponentId"></slot>
              </template>
            </Cta>
          </div>
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
    <div
      class="absolute right-8 top-20 z-20 flex flex-col justify-center lg:inset-y-0 lg:right-32"
    >
      <button
        class="bg-blue hover:bg-blue-darker flex h-12 w-12 items-center justify-center rounded-full lg:h-14 lg:w-14"
        aria-label="next"
        @click="nextSlide"
      >
        <ArrowRightSmall />
      </button>
    </div>
  </div>
  <div v-else-if="data?.cards?.length === 1">
    <div
      class="relative flex aspect-[3/2] w-full items-center lg:aspect-auto lg:h-[700px] lg:px-14"
    >
      <NuxtImg
        v-if="item?.image?.url"
        :src="item?.image?.url"
        :alt="item?.image?.alt"
        provider="cloudinary"
        class="absolute top-0 aspect-[375/308] w-full object-cover object-center lg:inset-0 lg:aspect-auto lg:h-full"
        loading="lazy"
        sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        fit="fill"
        width="1920"
        height="700"
      />

      <Cta
        :sub-title="item?.subtitle"
        :title="item?.title"
        :content="item?.copyDesktop"
        :content-secondary="item?.copyMobile"
        :link="item?.link"
        :has-form-component="item?.hasFormComponent"
        :form-component-id="item?.formComponentId"
        class="mt-[65%] lg:mt-0"
      >
        <template #default="{ formComponentId }">
          <slot name="default" :form-component-id="formComponentId"></slot>
        </template>
      </Cta>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import type { Ref } from 'vue'
import { Controller, Navigation, Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useWindowSize } from '@vueuse/core'

import Cta from '../molecules/Cta.vue'
import ArrowRightSmall from '../atoms/Icons/ArrowRightSmall.vue'
import NuxtImg from '../atoms/NuxtImg.vue'

import { ICardData } from '../../types'

export interface ICardBigHeroCtaData extends ICardData {
  copyDesktop: string
  copyMobile: string
  hasFormComponent: boolean
  formComponentId: string
}

export interface IBigHeroCta {
  id: string
  cards: ICardBigHeroCtaData[]
}

interface Props {
  data: IBigHeroCta
}

const props = defineProps<Props>()

const item = computed(() => props.data?.cards?.[0])

const { width } = useWindowSize()

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}

const nextSlide = () => controlledSwiper.value?.slideNext(500, false)

const widthScreen = computed(() => width.value)

watch(widthScreen, () => {
  if (!controlledSwiper?.value) {
    return
  }
  controlledSwiper?.value.update()
})
</script>

<style scoped>
.swiper {
  padding-bottom: 0px;
}
</style>
