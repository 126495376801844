<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="flex flex-col">
    <div
      v-for="(item, index) in data?.cards"
      :key="item?.title || index"
      :class="[item.rowStyle !== 'dotted-lines' && 'mb-5 last:mb-0 lg:mb-24']"
    >
      <div
        class="flex flex-col"
        :class="[
          reverse(index) ? 'lg:flex-row-reverse' : 'lg:flex-row',
          item.rowStyle !== 'dotted-lines' && 'mb-5 last:mb-0 lg:mb-24',
        ]"
      >
        <div
          v-if="item?.mediaType === 'image'"
          class="h-full px-4 lg:basis-[45%] lg:px-0 xl:basis-[38%]"
        >
          <Image
            v-if="item?.image?.url && item?.image?.type === 'image'"
            :url="item.image.url"
            :alt="item.image.alt"
            :class="[
              'aspect-square w-full shrink-0 overflow-hidden rounded-2xl lg:aspect-[3/2.5] ',
              reverse(index)
                ? ' lg:rounded-br-none lg:rounded-tr-none'
                : ' lg:rounded-bl-none lg:rounded-tl-none',
            ]"
          />
        </div>

        <div
          v-if="item?.mediaType === 'video' && item?.video?.url"
          class="shrink-0 lg:basis-[60%] lg:px-4"
        >
          <VideoIframe
            v-if="item?.video?.url"
            :url="item.video.url"
            class="aspect-video w-full self-start lg:rounded-2xl"
          />
        </div>

        <div
          class="flex shrink flex-col justify-center px-4 pt-6 lg:px-36 lg:pt-10 xl:pt-24"
        >
          <div class="lg:max-w-2xl">
            <SubTitle class="text-blue">
              {{ item.subtitle }}
            </SubTitle>
            <Title>
              {{ item.title }}
            </Title>
            <div
              class="mt-3.5 font-sans text-base leading-snug text-black lg:text-lg lg:leading-normal"
              v-html="item.copy"
            ></div>
            <Link
              v-if="item?.link?.url"
              :title="item.link.title"
              :link="item.link.url"
              :external="item.link?.type === 'ext'"
              class="!mt-10 w-full lg:w-auto"
              :aria-label="item.title"
            />
          </div>
        </div>
      </div>
      <div
        v-if="item.rowStyle === 'dotted-lines'"
        class="flex w-full justify-center py-16 lg:py-36"
      >
        <Dots :reverse="reverse(index)" class="hidden lg:block" />
        <DotsMobile :reverse="reverse(index)" class="lg:hidden" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SubTitle from '../atoms/SubTitle.vue'
import Title from '../atoms/Title.vue'
import Link from '../atoms/Link.vue'
import Image from '../atoms/Image.vue'
import VideoIframe from '../atoms/VideoIframe.vue'
import Dots from '../atoms/Dots.vue'
import DotsMobile from '../atoms/DotsMobile.vue'
import type { ICardData } from '../../types'

interface IImageTextCard extends ICardData {
  mediaType: 'image' | 'video'
  rowStyle?: string
  video?: {
    url?: string
  }
}

export interface IImageTextBlock {
  id: string
  cards?: IImageTextCard[]
}

interface Props {
  data: IImageTextBlock
}

defineProps<Props>()

const reverse = (index: number) => index % 2 !== 0
</script>
