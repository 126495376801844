<template>
  <div>
    <div
      v-if="podcast"
      class="mt-12 flex flex-col items-center overflow-hidden rounded-lg bg-white lg:flex-row lg:items-stretch"
      :class="[
        isActive
          ? 'shadow-[0_0_40px_0px_rgba(0,180,210,0.23)] lg:shadow-[0_0_30px_0px_rgba(0,180,210,0.23)]'
          : 'shadow-[0_0_40px_0px_rgba(51,51,51,0.1)] lg:shadow-[0_0_25px_0px_rgba(51,51,51,0.1)]',
      ]"
    >
      <NuxtImg
        v-if="podcast?.thumbnail_url"
        :src="podcast?.thumbnail_url"
        :alt="podcast.title"
        width="192"
        height="192"
        class="mb-4 ml-4 mt-6 rounded-lg lg:mt-4"
        loading="lazy"
      />
      <div class="flex w-full flex-1 flex-col justify-between px-7 pb-12 pt-8 lg:pb-8">
        <h2
          class="text-center font-sans text-lg font-bold text-black lg:text-left lg:text-xl"
        >
          {{ podcast.title }}
        </h2>
        <div>
          <div class="mb-4 mt-9 flex lg:mt-0">
            <div class="-mr-8 flex w-full items-center justify-center space-x-8 lg:mr-0">
              <button aria-label="prev" @click="seekPrev">
                <PrevIcon />
              </button>

              <button :aria-label="isPaused ? 'play' : 'pause'" @click="togglePlay">
                <PlayIcon v-if="isPaused || !isActive" />
                <PauseIcon v-else />
              </button>

              <button aria-label="next" @click="seekNext">
                <NextIcon />
              </button>
            </div>
            <Loader
              v-if="(isActive && isBuffering) || (isLoading && isPaused)"
              class="h-8 w-8"
            />
            <div v-else class="w-8"></div>
          </div>

          <div class="flex w-full items-center">
            <RangeSlider
              :id="id"
              class="mt-2 w-full"
              :max="duration"
              :value="isActive ? currentSeconds : 0"
              @update="updateSlider"
            />
            <div class="ml-4">
              <div
                v-if="
                  (currentPodcast !== id && !isActive) || duration - currentSeconds === 0
                "
                class="font-sans text-base text-black"
              >
                0:00
              </div>
              <div v-else class="font-sans text-base text-black">-{{ time }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watch, toRefs } from 'vue'
import PlayIcon from '../atoms/Icons/Play.vue'
import NextIcon from '../atoms/Icons/Next.vue'
import PrevIcon from '../atoms/Icons/Prev.vue'
import PauseIcon from '../atoms/Icons/Pause.vue'
import RangeSlider from '../atoms/RangeSlider.vue'
import Loader from '../atoms/Loader.vue'
import NuxtImg from '../atoms/NuxtImg.vue'

export interface IPodcastCard {
  id: string
  contoller: any
  currentPodcast: string
  duration: number
  currentSeconds: number
  isPaused: boolean
  isBuffering: boolean
}

const props = defineProps<IPodcastCard>()

const emit = defineEmits(['set-podcast'])

const podcast = ref<any>(null)
const isLoading = ref(false)

const { isBuffering } = toRefs(props)

const portionTime = durationInSeconds => {
  let hours = durationInSeconds / 3600
  let mins = (durationInSeconds % 3600) / 60
  let secs = (mins * 60) % 60

  hours = Math.trunc(hours)
  mins = Math.trunc(mins)
  secs = Math.trunc(secs)

  if (!hours && !mins && !secs) {
    return '0:00'
  }

  if (hours) {
    if (mins) {
      return secs ? `${hours}:${mins}:${secs}` : `${hours}:${mins}`
    } else {
      return secs ? `${hours}:${secs}` : `${hours}`
    }
  } else {
    if (mins) {
      return secs ? (secs < 10 ? `${mins}:0${secs}` : `${mins}:${secs}`) : `${mins}:00`
    } else {
      return secs ? `0:${secs}` : `0:00`
    }
  }
}

const time = computed(() => portionTime(props.duration - props.currentSeconds))
const isActive = computed(() => props.currentPodcast === props.id)

const updateSlider = (value: number) => {
  props.contoller.seek(value)
}

const togglePlay = () => {
  if (!props.contoller) {
    return
  }
  if (props.currentPodcast === props.id) {
    props.contoller.togglePlay()
    return
  }
  emit('set-podcast', props.id)
  props.contoller.loadUri('spotify:episode:' + props.id)
  isLoading.value = true
  props.contoller.play()
}

const seekNext = () => {
  props.contoller.seek(props.currentSeconds + 15)
}

const seekPrev = () => {
  props.contoller.seek(props.currentSeconds - 15)
}

const initPodcastCard = async () => {
  const data = await fetch(
    `https://open.spotify.com/oembed?url=https://open.spotify.com/episode/${props.id}`,
  ).then(response => response.json())

  podcast.value = data
}

watch(isBuffering, () => {
  if (isBuffering.value) {
    isLoading.value = false
  }
})

onMounted(() => initPodcastCard())
</script>
