<template>
  <div class="flex flex-1 flex-col">
    <label
      :for="name + formId"
      class="mb-1.5 inline-block font-sans text-base text-black lg:mb-3 lg:text-lg lg:leading-snug"
      :class="styleLabel"
    >
      {{ label }}
      <span v-if="required">*</span>
    </label>
    <textarea
      :id="name + formId"
      class="flex-1 rounded-md border-transparent p-3 leading-none focus:border-transparent focus:ring-0 lg:p-4 lg:text-xl lg:leading-none"
      :class="styleInput"
      :rows="rows"
      :placeholder="placeholder"
      :value="modelValue"
      :name="name"
      :hidden="hidden"
      :disabled="disabled"
      :read-only="readOnly"
      :required="required"
      @input="e => update(e, name)"
    />
  </div>
</template>

<script setup>
// eslint-disable-next-line vue/no-setup-props-destructure
defineProps({
  label: String,
  styleInput: String,
  styleLabel: String,
  rows: { type: Number, default: 10 },
  placeholder: String,
  modelValue: String,
  name: String,
  required: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  hidden: { type: Boolean, default: false },
  readOnly: { type: Boolean, default: false },
  formId: String,
});
const emit = defineEmits(['update:modelValue', 'setData']);

const update = (e, key) => {
  emit('setData', { key, value: e.target.value });
  emit('update:modelValue', e.target.value);
};
</script>
