<template>
  <div class="flex h-full w-full flex-col" :class="containerSize">
    <div v-if="data.image?.byline" class="self-end px-2 py-2 font-sans text-xs text-black lg:px-3 lg:py-3 lg:text-sm"
      :class="[data?.byline === 'bottom' && 'order-last']">
      {{ data.image?.byline }}
    </div>

    <div v-if="data?.image?.url" class="relative aspect-[343/230] w-full overflow-hidden" :class="imageSize">
      <NuxtImg v-if="data?.image?.url" :src="data?.image?.url" :alt="data?.image?.alt" provider="cloudinary"
        loading="lazy" class="absolute h-full w-full object-cover object-center"
        sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw" :fit="data.originalAspect ? 'inside' : 'fit'" :width="size.width"
        :height="size.height" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import NuxtImg from '../atoms/NuxtImg.vue'
import { IImageData } from '../../types'

export interface IImageBlock {
  id: string
  image?: IImageData
  desktopSize?: string
  byline?: string
  originalAspect?: boolean
}

interface Props {
  data: IImageBlock
}

const props = defineProps<Props>()

const imageSize = computed<string>(() => {
  switch (props.data?.desktopSize) {
    case 'extra_wide':
      return props.data?.originalAspect ? '' : 'xl:aspect-[1920/750]'
    case 'wide':
      return props.data?.originalAspect ? 'lg:rounded-2xl lg:mx-4 2xl:mx-0' : 'xl:aspect-[1260/500] lg:rounded-2xl lg:mx-4 2xl:mx-0'
    case 'small':
      return props.data?.originalAspect ? 'lg:rounded-2xl lg:mx-4 2xl:mx-0' : 'xl:aspect-[650/365] lg:rounded-2xl lg:mx-4 2xl:mx-0'
    default:
      return props.data?.originalAspect ? '' : 'xl:aspect-[1920/750]'
  }
})

const size = computed<{ width: number; height?: number }>(() => {
  switch (props.data?.desktopSize) {
    case 'extra_wide':
      return props.data?.originalAspect ? { width: 1920 } : { width: 1920, height: 750 }
    case 'wide':
      return props.data?.originalAspect ? { width: 1260 } : { width: 1260, height: 500 }
    case 'small':
      return props.data?.originalAspect ? { width: 650 } : { width: 650, height: 365 }
    default:
      return props.data?.originalAspect ? { width: 1920 } : { width: 1920, height: 750 }
  }
})

const containerSize = computed<string>(() => {
  switch (props.data?.desktopSize) {
    case 'extra_wide':
      return 'xl:max-w-[1920px]'
    case 'wide':
      return 'xl:max-w-[1260px]'
    case 'small':
      return 'xl:max-w-[650px]'
    default:
      return 'xl:max-w-[1920px]'
  }
})
</script>
