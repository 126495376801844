<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="data?.cards && data?.cards.length > 1"
    class="bg-blue-lighter relative lg:bg-transparent"
  >
    <ClientOnly>
      <Swiper
        :modules="[Controller, Navigation, Pagination]"
        :controller="{ control: controlledSwiper }"
        :slides-per-view="1"
        :space-between="0"
        :centered-slides="true"
        :loop="data?.cards.length > 2 ? true : false"
        :pagination="pagination"
        @swiper="setSwiper"
      >
        <SwiperSlide
          v-for="(item, index) in data.cards"
          v-slot="{ isPrev, isNext }"
          :key="data.id + index"
        >
          <div
            class="flex justify-center py-12 lg:flex-row lg:py-9"
            :class="[
              data?.cards.length > 1 &&
                isPrev &&
                'translate-x-20 opacity-40 sm:translate-x-36 lg:translate-x-0',
              data?.cards.length > 1 &&
                isNext &&
                '-translate-x-20 opacity-40 sm:-translate-x-24 lg:translate-x-0',
              item.image?.url ? 'flex-col-reverse' : 'flex-col',
            ]"
          >
            <div
              class="flex-col items-center pt-8 lg:pt-0"
              :class="[!item.image?.url ? 'hidden' : 'flex']"
            >
              <NuxtImg
                v-if="item?.image?.url"
                :src="item.image?.url"
                :alt="item.image?.alt || ''"
                provider="cloudinary"
                width="400"
                height="400"
                class="aspect-square !w-[100px] rounded-full lg:!w-[300px]"
                loading="lazy"
                sizes="xs:150px sm:150px md:150px lg:400px xl:400px xxl:400px"
                fit="fill"
              />
              <div class="mt-4 text-center lg:mt-16">
                <div class="text-blue font-sans lg:text-xl">{{ item.name }}</div>
                <div class="mt-4 flex flex-col space-y-3 text-center lg:space-y-4">
                  <div class="font-sans lg:text-xl">
                    {{ item.position }}
                  </div>
                  <div class="font-sans lg:text-xl">
                    {{ item.date }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="mx-14 flex max-w-[600px] flex-col items-center space-y-3 self-center py-4 lg:ml-[84px] lg:mr-12 lg:items-start lg:self-start"
            >
              <Quotes class="h-4 lg:h-auto lg:-translate-x-14" />
              <h3 class="hidden font-serif text-3xl font-bold leading-normal lg:block">
                {{ item.title }}
              </h3>
              <div
                class="space-y-4 text-center font-sans text-sm lg:text-left lg:text-lg"
                v-html="item.copy"
              ></div>

              <Quotes class="mt-4 h-4 lg:h-auto lg:translate-x-10 lg:self-end" />
              <div v-if="!item.image?.url" class="mt-9 text-center lg:mt-0 lg:text-left">
                <div class="text-blue font-sans lg:text-xl">{{ item.name }}</div>
                <div class="mt-4 flex flex-col lg:space-y-4">
                  <div v-if="item?.position" class="font-sans lg:text-xl">
                    {{ item.position }}
                  </div>
                  <div v-if="item?.date" class="font-sans lg:text-xl">
                    {{ item.date }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <SliderNavigation v-if="data?.cards.length > 1" :slider="controlledSwiper" />
    </ClientOnly>
  </div>
  <div v-else-if="data?.cards" class="bg-blue-lighter relative lg:bg-transparent">
    <div
      class="flex justify-center py-12 lg:flex-row lg:py-9"
      :class="[data?.cards[0].image?.url ? 'flex-col-reverse' : 'flex-col']"
    >
      <div
        class="flex-col items-center pt-8 lg:pt-0"
        :class="[!data?.cards[0].image?.url ? 'hidden' : 'flex']"
      >
        <NuxtImg
          v-if="data?.cards?.[0].image?.url"
          :src="data?.cards[0].image?.url"
          :alt="data?.cards[0].image?.alt || ''"
          provider="cloudinary"
          width="400"
          height="400"
          class="aspect-square !w-[100px] rounded-full lg:!w-[300px]"
          loading="lazy"
          sizes="xs:150px sm:150px md:150px lg:400px xl:400px xxl:400px"
          fit="fill"
        />
        <div class="mt-4 text-center lg:mt-16">
          <div class="text-blue font-sans lg:text-xl">{{ data?.cards[0].name }}</div>
          <div class="mt-4 flex flex-col space-y-3 text-center lg:space-y-4">
            <div class="font-sans lg:text-xl">
              {{ data?.cards[0].position }}
            </div>
            <div class="font-sans lg:text-xl">
              {{ data?.cards[0].date }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="mx-14 flex max-w-[600px] flex-col items-center space-y-3 self-center py-4 lg:ml-[84px] lg:mr-12 lg:items-start lg:self-start"
      >
        <Quotes class="h-4 lg:h-auto lg:-translate-x-14" />
        <h3 class="hidden font-serif text-3xl font-bold leading-normal lg:block">
          {{ data?.cards[0].title }}
        </h3>
        <div
          class="space-y-4 text-center font-sans text-sm lg:text-left lg:text-lg"
          v-html="data?.cards[0].copy"
        ></div>

        <Quotes class="mt-4 h-4 lg:h-auto lg:translate-x-10 lg:self-end" />
        <div
          v-if="!data?.cards[0].image?.url"
          class="mt-9 text-center lg:mt-0 lg:text-left"
        >
          <div class="text-blue font-sans lg:text-xl">{{ data?.cards[0].name }}</div>
          <div class="mt-4 flex flex-col space-y-3 lg:space-y-4">
            <div class="font-sans lg:text-xl">
              {{ data?.cards[0].position }}
            </div>
            <div v-if="data?.cards[0].date" class="font-sans lg:text-xl">
              {{ data?.cards[0].date }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref, computed, watch } from 'vue'
import type { Ref } from 'vue'
import { Controller, Navigation, Pagination, Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { useWindowSize } from '@vueuse/core'

import Quotes from '../atoms/Icons/Quotes.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import NuxtImg from '../atoms/NuxtImg.vue'

import { ICardData } from '../../types'

interface ITestimonialBlockCard extends ICardData {
  date?: string
  position?: string
  name?: string
}

export interface ITestimonialBlock {
  id: string
  cards?: ITestimonialBlockCard[]
}

interface Props {
  data: ITestimonialBlock
}

defineProps<Props>()

const { width } = useWindowSize()

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const pagination = computed(() => ({
  clickable: true,
  dynamicBullets: true,
}))

const widthScreen = computed(() => width.value)

watch(widthScreen, () => {
  controlledSwiper.value && controlledSwiper.value.update()
})

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}
</script>

<style>
.swiper-slide-prev {
  z-index: 1000;
}
</style>
