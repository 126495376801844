<template>
  <Container class="!max-w-[1362px]">
    <SubTitle class="text-blue mb-3 block text-center lg:mb-14">
      {{ data?.subtitle }}
    </SubTitle>
    <div
      class="hidden flex-row xl:flex"
      :class="[
        data?.cards && data?.cards?.length <= 2 ? 'justify-center' : 'justify-between',
      ]"
    >
      <template v-for="(item, index) in data?.cards" :key="index">
        <div v-if="index !== 0" class="mx-4 h-[170px] w-0.5 bg-[#CAF7FF]"></div>
        <FactsCard :data="item" />
      </template>
    </div>
    <div class="relative xl:hidden">
      <ClientOnly>
        <Swiper
          :modules="[Controller, Navigation, Pagination]"
          :controller="{ control: controlledSwiper }"
          :slides-per-view="1"
          :space-between="30"
          :centered-slides="true"
          :loop="false"
          :observer="true"
          :pagination="{
            dynamicBullets: true,
          }"
          @swiper="setSwiper"
        >
          <SwiperSlide v-for="(item, index) in data?.cards" :key="index">
            <FactsCard :data="item" />
          </SwiperSlide>
        </Swiper>
        <SliderNavigation :slider="controlledSwiper" />
      </ClientOnly>
    </div>
    <div v-if="data.link" class="mt-14 flex justify-center lg:mt-20">
      <Link
        type="outline"
        :title="data.link.title"
        :link="data.link.url"
        :external="data?.link?.type === 'ext'"
        class="mx-4 w-full lg:mx-auto lg:w-auto"
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { ref } from 'vue'
import type { Ref } from 'vue'
import { Controller, Navigation, Pagination, Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import SubTitle from '../atoms/SubTitle.vue'
import FactsCard from '../molecules/FactsCard.vue'
import SliderNavigation from '../atoms/SliderNavigation.vue'
import Container from '../atoms/Container.vue'
import Link from '../atoms/Link.vue'
import { ILinkData } from '../../types'
import { IFactsCard } from '../molecules/FactsCard.vue'

export interface IFactsBlock {
  id: string
  title?: string
  subtitle?: string
  copy?: string
  link?: ILinkData
  cards?: IFactsCard[]
}

interface Props {
  data: IFactsBlock
}

defineProps<Props>()

const controlledSwiper: Ref<SwiperType | undefined> = ref()

const setSwiper = (swiper: SwiperType) => {
  controlledSwiper.value = swiper
}
</script>

<style>
.swiper {
  padding-bottom: 70px;
}
.swiper-pagination {
  margin-top: 70px;
}
</style>
