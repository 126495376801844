<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-if="showConfirmation"
    ref="confirmation"
    class="space-y-4 rounded-xl bg-blue-lighter p-6 lg:rounded-2xl lg:p-9"
  >
    <h2
      v-if="data.confirmation.title"
      class="mb-6 font-sans text-3xl font-bold text-blue lg:text-4xl"
    >
      {{ data.confirmation.title }}
    </h2>
    <div
      class="font-base custom-list prose font-sans text-base leading-[21px] lg:prose-lg prose-a:text-blue prose-a:no-underline hover:prose-a:text-blue-dark lg:text-lg lg:leading-[26px]"
      v-html="data.confirmation.message"
    ></div>
  </div>
  <form
    v-else-if="data?.elements"
    ref="formRef"
    class="space-y-4 rounded-xl bg-blue-lighter px-6 py-4 lg:rounded-2xl lg:p-9"
    @submit.prevent="onSubmit"
  >
    <h4 class="mb-10 font-serif text-xl text-black lg:text-2xl">{{ data?.title }}</h4>
    <WebformElement
      v-for="item in data.elements"
      :key="item[0]"
      v-model="formFields[item[0]]"
      :iid="item[0]"
      :element="item[1]"
      :is-loading="isLoading"
      :form-id="data.id"
    />
    <p class="font-bold text-red-600">{{ error }}</p>
  </form>
</template>

<script setup>
import { reactive, ref, watch, nextTick } from 'vue';
import { useForm, useRecaptcha, flattenObj } from 'refresh-common';
import WebformElement from '@/components/molecules/WebformElement.vue';
import { useRuntimeConfig } from '#app';
const config = useRuntimeConfig();

const props = defineProps({
  data: Object,
});
const { isLoading, sendForm, updateDataForm, error } = useForm({
  cmsBasePath: config.public.cmsBasePath,
});
const { addRecatcha, recaptcha } = useRecaptcha({
  recaptchaSiteKey: config.public.recaptchaSiteKey,
});

const formFields = reactive({});
const formRef = ref();
const showConfirmation = ref(false);
const confirmation = ref(null);

updateDataForm(props.data.id, {});

watch(formFields, () => {
  addRecatcha();
});

const unwrapKeys = inputArray => {
  const resultsArray = inputArray.map(inputString => {
    const startIndex = inputString.indexOf('[') + 1;
    const endIndex = inputString.indexOf(']');

    if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex, endIndex);
    } else {
      return '';
    }
  });
  return resultsArray;
};

const hasBrackets = arr => {
  if (Array.isArray(arr) === false) {
    return false;
  }
  const regex = /^[a-zA-Z0-9_]+\[[a-zA-Z0-9]+\]$/;
  for (let str of arr) {
    if (regex.test(str)) {
      return true;
    }
  }
  return false;
};

const onSubmit = async () => {
  // TODO: Fix recaptcha -- returns {"success":false}
  const success = await recaptcha();
  /* if (!success) {
    return;
  }*/

  // CheckboxesMulti values are wrapped in a certain way to make conditional fields work, here we check for that and unwrap them
  const data = flattenObj(JSON.parse(JSON.stringify(formFields)));
  const result = {};
  const original = Object.entries(data);
  original.forEach(([key, value]) => {
    if (hasBrackets(value)) {
      result[key] = unwrapKeys(value);
    } else {
      result[key] = value;
    }
  });
  // console.log('res', result);
  // return;
  const sended = await sendForm(props.data.id, result);
  if (sended) {
    showConfirmation.value = true;
    await nextTick();
    confirmation.value && confirmation.value.scrollIntoView({ behavior: 'smooth' });
  }
};
</script>
