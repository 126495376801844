<!-- eslint-disable vue/no-v-html -->
<template>
  <Container class="flex !max-w-[1362px] flex-col">
    <div
      v-for="(item, index) in cards"
      :key="index"
      class="mb-8 flex flex-col lg:mb-9 lg:flex-row lg:items-start"
    >
      <NuxtLink
        :to="item?.link?.url"
        class="relative mb-4 flex aspect-[3/2] overflow-hidden rounded-xl lg:mb-0 lg:aspect-[3/1.5] lg:basis-1/2 lg:rounded-2xl xl:aspect-[3/1.2]"
        :class="[item?.link?.url && 'cursor-pointer']"
        :aria-label="item.title"
        :target="item.link?.type === 'ext' ? '_blank' : '_self'"
      >
        <NuxtImg
          v-if="item?.image?.url"
          :src="item.image.url"
          :alt="item.image.alt || item.title"
          class="w-full object-cover object-center"
          loading="lazy"
        />
      </NuxtLink>
      <div class="flex h-full flex-1 flex-col justify-center lg:ml-9 lg:basis-1/2">
        <div class="max-w-2xl">
          <h3
            class="lg:text-28 mb-4 font-serif text-2xl font-bold leading-none text-black lg:leading-snug"
          >
            {{ item.title }}
          </h3>
          <div
            class="line-clamp-4 font-sans text-base leading-snug text-black lg:text-lg lg:leading-snug xl:line-clamp-4 [&_*:nth-child(n+2)]:hidden"
            v-html="item.copy"
          ></div>
          <LinkSecondary
            v-if="item.link"
            type="blue"
            class="mt-8 lg:mb-2 lg:mt-14"
            :title="item?.link?.title || 'Läs mer'"
            :aria-label="item.title"
            :link="item.link.url"
            :external="item.link?.type === 'ext'"
          />
        </div>
      </div>
    </div>
    <div
      v-if="data?.link?.url || (data?.cards && limit <= data?.cards?.length)"
      class="mt-14 flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0"
    >
      <Link
        v-if="data?.link?.url"
        :title="data?.link.title"
        class="w-[90%] lg:w-auto"
        :link="data.link.url"
        :external="data.link?.type === 'ext'"
      />
      <Button
        v-if="data?.cards && limit <= data?.cards?.length"
        :title="data?.loadMoreText || 'ladda mer'"
        type="outline"
        class="w-[90%] px-8 lg:w-auto lg:px-14"
        @click="loadMore"
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import LinkSecondary from '../atoms/LinkSecondary.vue'
import Link from '../atoms/Link.vue'
import Container from '../atoms/Container.vue'
import Button from '../atoms/Button.vue'
import NuxtImg from '../atoms/NuxtImg.vue'
import { ILinkData, ICardData } from '../../types'

export interface IListBlock {
  id?: string
  cards?: ICardData[]
  link?: ILinkData
  loadMoreText?: string
}

interface Props {
  data: IListBlock
}

const props = defineProps<Props>()

const getDataElement = (cards: ICardData[], limit: number): ICardData[] => {
  if (!cards || !cards.length) {
    return []
  }

  return cards.slice(0, limit)
}

const limit = ref(4)
const cards = ref(getDataElement(props.data?.cards || [], limit.value))

const loadMore = () => {
  limit.value = limit.value + 4
}

watch(limit, () => {
  cards.value = getDataElement(props.data?.cards || [], limit.value)
})
</script>
