<template>
  <div>
    <h3 class="mb-6 font-serif text-3xl font-bold text-black">
      {{ petitions.toLocaleString('sv') }} {{ $t('petitionCounter.title') }}
      {{ goal.toLocaleString('sv') }}!
    </h3>
    <div class="mb-6 h-10 w-full rounded-lg bg-grey-light lg:h-12 lg:w-[340px]">
      <div
        class="transition-width h-10 rounded-lg bg-blue duration-1000 lg:h-12"
        :style="percent"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref } from 'vue';

const props = defineProps({
  formId: {
    type: String,
    required: true,
  },
});

const petitions = ref(0);
const defaultGoal = 10000;

// TODO: Implement a better goal algorithm
const goal = computed(() => {
  if (petitions.value < defaultGoal) {
    // If the number of signatures are lower than the default target,
    // we return the default target
    return defaultGoal;
  } else {
    if (petitions.value >= 1000000) {
      return 1000500;
    } else if (petitions.value >= 900000) {
      return 1000000;
    } else if (petitions.value >= 800000) {
      return 900000;
    } else if (petitions.value >= 700000) {
      return 800000;
    } else if (petitions.value >= 600000) {
      return 700000;
    } else if (petitions.value >= 500000) {
      return 600000;
    } else if (petitions.value >= 450000) {
      return 500000;
    } else if (petitions.value >= 400000) {
      return 450000;
    } else if (petitions.value >= 350000) {
      return 400000;
    } else if (petitions.value >= 300000) {
      return 350000;
    } else if (petitions.value >= 275000) {
      return 300000;
    } else if (petitions.value >= 250000) {
      return 275000;
    } else if (petitions.value >= 225000) {
      return 250000;
    } else if (petitions.value >= 200000) {
      return 225000;
    } else if (petitions.value >= 175000) {
      return 200000;
    } else if (petitions.value >= 150000) {
      return 175000;
    } else if (petitions.value >= 125000) {
      return 150000;
    } else if (petitions.value >= 100000) {
      return 125000;
    } else if (petitions.value >= 90000) {
      return 100000;
    } else if (petitions.value >= 75000) {
      return 90000;
    } else if (petitions.value >= 60000) {
      return 75000;
    } else if (petitions.value >= 50000) {
      return 60000;
    } else if (petitions.value >= 40000) {
      return 50000;
    } else if (petitions.value >= 30000) {
      return 40000;
    } else if (petitions.value >= 20000) {
      return 30000;
    } else if (petitions.value >= 15000) {
      return 20000;
    } else if (petitions.value >= 10000) {
      return 15000;
    } else if (petitions.value >= 5000) {
      return 10000;
    }
    // Otherwise we return the next thousand
    return Math.ceil((petitions.value + 1) / 1000) * 1000;
  }
});

const getCount = async () => {
  const response = await $fetch(
    `https://interactions-eaeec.firebaseio.com/counters/${props.formId}.json`,
  );
  petitions.value = response || 0; // If the data is null, we set it to 0 instead
};

const percent = computed(() => 'width:' + (petitions.value / goal.value) * 100 + '%');

onMounted(() => getCount());
</script>
