<template>
  <ClientOnly fallback-tag="span" fallback="Loading ...">
    <div
      v-if="y > (lgAndLarger ? viewPortHeight * 4 : viewPortHeight * 6)"
      class="group fixed bottom-8 right-8 z-50 flex cursor-pointer flex-row items-center lg:bottom-10 lg:right-16"
      @click="goToTop"
    >
      <span
        class="text-blue group-hover:text-blue-dark mr-8 hidden font-serif text-xl uppercase lg:block"
      >
        {{ $t('up') }}
      </span>
      <div class="bg-blue group-hover:bg-blue-dark block rounded-full p-[10px]">
        <ArrowUp />
      </div>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useWindowScroll, useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import ArrowUp from './Icons/ArrowUp.vue'

const props = defineProps({
  blockTo: Object,
})

const { y } = useWindowScroll()
const breakpoints = useBreakpoints(breakpointsTailwind)
const lgAndLarger = breakpoints.greaterOrEqual('lg')

const viewPortHeight = computed(() => window.innerHeight)

const goToTop = () => {
  props.blockTo?.scrollIntoView({ behavior: 'smooth' })
}
</script>
