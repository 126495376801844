<template>
  <div class="flex flex-col space-y-2">
    <div class="font-text font-sans text-black lg:text-lg">
      {{ title }}
      <span v-if="required">*</span>
    </div>
    <div
      v-for="(item, index) in Object.entries(options)"
      :key="id + '[' + item[0] + ']'"
      class="flex items-center"
    >
      <input
        :id="id + '[' + item[0] + ']'"
        :name="id + '[' + item[0] + ']'"
        type="checkbox"
        :required="index === 0 && requierdFiels"
        class="mr-2 h-7 w-7 appearance-none rounded outline-0 checked:bg-blue focus:ring-0 focus:checked:bg-blue hover:checked:bg-blue-dark lg:h-8 lg:w-8"
        @input="e => update(e, id + '[' + item[0] + ']')"
      />
      <label
        :for="id + '[' + item[0] + ']'"
        class="text-l5 block text-base leading-normal lg:text-lg"
      >
        {{ item[1] }}
      </label>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, computed } from 'vue';
const props = defineProps({
  title: String,
  options: Object,
  id: String,
  modelValue: Object,
  required: Boolean,
  formId: String,
});
const emit = defineEmits(['update:modelValue', 'setData']);
const inputValue = reactive({});

const requierdFiels = computed(() =>
  props.required ? Object.entries(inputValue).length === 0 : false,
);

const update = (e, key) => {
  emit('setData', { key, value: e.target.checked });
  if (e.target.checked) {
    inputValue[e.target.name] = e.target.id;
  } else {
    delete inputValue[e.target.name];
  }
};

// Added to change [ 'agent_crm_interest_ids[BLIVOLONTAR]', ... ] to [ 'BLIVOLONTAR', ... ] which the webform rest module requires
const unwrapKeys = inputArray => {
  const resultsArray = inputArray.map(inputString => {
    const startIndex = inputString.indexOf('[') + 1;
    const endIndex = inputString.indexOf(']');

    if (startIndex !== -1 && endIndex !== -1) {
      return inputString.substring(startIndex, endIndex);
    } else {
      return '';
    }
  });
  return resultsArray;
};

watch(inputValue, () => {
  emit('update:modelValue', { [props.id]: Object.values(inputValue) });
});
</script>
