<template>
  <div>
    <label class="flex cursor-pointer items-center text-blue">
      <input
        type="radio"
        :name="name"
        :checked="checked"
        :value="value"
        :required="required"
        class="flex cursor-pointer items-center justify-center ring-0 ring-transparent ring-offset-0 checked:shadow-none checked:ring-0 focus:ring-0"
        @input="e => update(e, name)"
      />
      <span class="ml-4 text-base text-black lg:text-lg">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script setup>
defineProps({
  checked: Boolean,
  id: String,
  name: String,
  value: String,
  modelValue: String,
  required: Boolean,
});

const emit = defineEmits(['update:modelValue', 'setData']);
const update = (e, key) => {
  emit('setData', { key, value: e.target.value });
  emit('update:modelValue', e.target.value);
};
</script>

<style>
input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border: 2px solid currentColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  place-content: center;
  position: relative;
}

input[type='radio']::before {
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: none;
  background-color: currentColor;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

input[type='radio']:checked {
  background-color: transparent;
  border: 2px solid currentColor;
}

input[type='radio']:focus {
  outline: 0;
}
input[type='radio']:checked:hover {
  outline: 0;
  background-color: transparent;
  border: 2px solid currentColor;
}

[type='radio']:checked:focus {
  outline: 0;
  background-color: transparent;
  border: 2px solid currentColor;
}
</style>
